import React from 'react'

const Insights = () => (
  <>
    <h1>Insights</h1>
    <h3>Rooted in academia with R&D advantages to pioneer in inventing and developing algorithms, machine learning models, scientific-to-business-proven-technology-based solutions, and production products in the cognitive humanoid field, we are a bridge between academia and industry in AI & Robotics.</h3>
    <h4>We help our customers solve their problems either with our own Products or Tailored-built Solutions.</h4>
  </>
)

export default Insights
