import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Insights from '../components/Insights'
import config from '../../data/SiteConfig'

const InsightsPage = () => (
  <Layout>
    <main>
      <Helmet title={`Insights | ${config.siteTitle}`} />
      <Insights />
    </main>
  </Layout>
)

export default InsightsPage
